<template>
    <Template class="profile-view">

        ProfileView

    </Template>
</template>

<script>

import Template from '@/templates/DefaultTemplate'

export default {

    name: 'ProfileView',

    components: {
        Template
    }

}

</script>

<style lang="scss" scoped>

.profile-view {

    display: block;

}

</style>